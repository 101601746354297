.herida-individual {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.herida-header {
  background-color: #00bcd4;
}

.herida-title{
  margin: 0 0 0 20px;
}

.herida-container-btn {
  margin: 15px 40px;
  display: flex;
  align-items: center;
}

.herida-container {
  margin: 15px 40px;
}

.herida-back-btn {
  padding: 5px 10px;
}

.herida-content {
  display: flex;
  flex-direction: column;
  .first-impact-box-margins {
    margin: 0 auto 0 auto;
  }
}

.herida-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px;
  display: flex;
  border-radius: 10px;
  width: 250px;
  height: 70px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;

  p {
    font-size: large;
    margin: 0;
  }
}

.herida-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.herida-modal-content {
  background-color: white;
}

.modal-actions {
  margin: 10px 24px 16px 0;
}

.portfolio-menu{
	text-align:center;
}

.portfolio-item .item{
	/*width:303px;*/
	float:left;
	margin-bottom:10px;
}