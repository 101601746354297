.autoselect-options {
  padding: 6px 16px;
  line-height: 1.5;
  width: auto;
  min-height: auto;
  font-size: 1rem;
  letter-spacing: 0.00938em;
  font-weight: 400;
  cursor: pointer;
}

.autoselect-options:hover {
  background-color: rgba(0, 0, 0, 0.14) !important;
}
