/* Calendar Module Style */

.rbc-calendar {
  .app-calendar & {
    @include display-flex(flex, column, nowrap);
    @include flex(1);
    min-height: calc(100vh - 160px);
  }

  .app-cul-calendar & {
    min-height: calc(100vh - 200px);
  }
}

.rbc-event {
  background-color: $app-primary;
}

.Planning-color { // Planeado - Azul
  background-color: $app-primary !important;
}

.Cancelled-color { // Cancelado - Rojo
  background-color: $danger !important;
}

.Registered-color {  //Impacto finalizado - verde
  background-color: #4CAF50;
}

.InProgress-color {  //Inciado pero no esta finalizado - Naranja
  background-color: #ff9800;
}

.rbc-event.Cancelado {
  background-color: $danger;
}
.rbc-event.training {
  background-color: $sepia;
}

.rbc-event.rbc-selected {
  background-color: darken($app-primary, 10%);
}

.rbc-event.rbc-selected.Cancelado {
  background-color: darken($danger, 10%);
}
.rbc-event.rbc-selected.training {
  background-color: darken($sepia, 10%);
}

.rbc-slot-selection {
  background-color: rgba($app-primary, 0.7);
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: $white;
  background-image: none;
  @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
  //box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: rgba($app-primary, 0.9);
  border-color: $app-primary;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
  color: $white;
  background-color: rgba($app-primary, 0.9);
  border-color: $app-primary;
}

.rbc-toolbar button:focus {
  color: $white;
  background-color: rgba($app-primary, 0.9);
  border-color: $app-primary;
}

.rbc-toolbar button:hover {
  color: $white;
  background-color: rgba($app-primary, 0.9);
  border-color: $app-primary;
}

.rbc-month-row {
  min-height: 100px;
}

.rbc-event {
  min-height: 50px;

  &:focus {
    outline: none;
  }
}

@media screen and (max-width: 867px) {
  .rbc-toolbar {
    @include display-flex(flex, column, nowrap);
    @include align-items(flex-start);
  }

  .rbc-toolbar .rbc-toolbar-label {
    margin: 8px 0;
  }
}

@media screen and (max-height: 600px) {
  .rbc-calendar {
    .app-calendar & {
      min-height: 400px;
    }

    .app-cul-calendar & {
      min-height: 400px;
    }
  }
}

@media screen and (max-width: 467px) {
  .rbc-toolbar {
    font-size: 12px;
  }

  .rbc-toolbar button {
    padding: 5px 10px;
  }
}

