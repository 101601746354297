.MuiInputLabel-outlined {
  z-index: auto !important;
}

.MuiButton-textSecondary {
  color: #0d47a1 !important;
}

.border-dialog-card {
  box-shadow: none !important;
}

.bg-iclusig {
  background-color: #f6bb43 !important;
}

.text-iclusig {
  color: #f6bb43 !important;
}

.bg-galafold {
  background-color: #967bdc !important;
}

.text-galafold {
  color: #967bdc !important;
}

.bg-remodulin {
  background-color: #8cc051 !important;
}

.text-remodulin {
  color: #8cc051 !important;
}

.bg-kalbitor {
  background-color: #e9573e !important;
}

.text-kalbitor {
  color: #e9573e !important;
}

.bg-first-impact {
  background-color: #3baeda !important;
}

.text-first-impact {
  color: #3baeda !important;
}

.bg-accomp,
.bg-auth-eps,
.bg-med-dlv,
.bg-call,
.bg-rad-olf,
.bg-rad-eps,
.bg-proc-fu,
.bg-home-visit,
.bg-hosp-visit {
  background-color: #1d7787 !important;
}

.text-accomp,
.text-auth-eps,
.text-med-dlv,
.text-call,
.text-rad-olf,
.text-rad-eps,
.text-proc-fu,
.text-home-visit,
.text-hosp-visit {
  color: #1d7787 !important;
}

.bg-med-auth,
.bg-pac-auth,
.bg-presc,
.bg-rad-eps-ok,
.bg-auth-ok,
.bg-rad-olf-ok,
.bg-dlv-ok,
.bg-green {
  background-color: #4caf50 !important;
}

.text-med-auth,
.text-pac-auth,
.text-presc,
.text-rad-eps-ok,
.text-auth-ok,
.text-rad-olf-ok,
.text-dlv-ok,
.text-green {
  color: #4caf50 !important;
}

.bg-med-decl,
.bg-pac-decl,
.bg-rad-eps-error,
.bg-not-auth,
.bg-rad-olf-error,
.bg-not-dlv,
.bg-red,
.bg-pat-state-1 {
  background-color: #da2f40 !important;
}

.text-med-decl,
.text-pac-decl,
.text-rad-eps-error,
.text-not-auth,
.text-rad-olf-error,
.text-not-dlv,
.text-red,
.text-pat-state-1 {
  color: #da2f40 !important;
}

.bg-presc-error,
.bg-auth-error,
.bg-dlv-error {
  background-color: #ed7d3a !important;
}

.text-presc-error,
.text-auth-error,
.text-dlv-error {
  color: #ed7d3a !important;
}

.bg-new-presc {
  background-color: #656d78 !important;
}

.text-new-presc {
  color: #656d78 !important;
}

.first-impact-box-margins {
  margin-right: 19px;
  margin-left: -9px;
}

.select-margins {
  margin-top: 10px;
}

.float-right {
  float: right;
}

.noDisplay {
  display: none !important;
}

.geoMap {
  height: 60vh;
  width: 105vh;
}

@media screen and (max-width: 1296px) {
  .geoMap {
    height: 60vh;
    width: 100vh;
  }
}

@media screen and (max-width: 990px) {
  .geoMap {
    height: 60vh;
    width: 64vh;
  }
}

@media screen and (max-width: 672px) {
  .geoMap {
    height: 60vh;
    width: 36vh;
  }
}

@media screen and (max-width: 400px) {
  .geoMap {
    height: 60vh;
    width: 30vh;
  }
}

.patientPa-filter-container {
  background-color: white;
  padding: 10px 20px 10px 20px;
  margin: 0 0 -20px 0;
}

.patientPa-btn-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//=======================================COLORS=========================================

.bg-red-pantone { //signifor
  background-color: #E43A44 !important;
}

.text-red-pantone {
  color: #E43A44 !important;
}

.bg-keppel { //cystagon
  background-color: #00B3A0 !important;
}

.text-keppel {
  color: #00B3A0 !important;
}

.bg-picton-blue { //cystadrops
  background-color: #00A7DE !important;
}

.text-picton-blue {
  color: #00A7DE !important;
}

.bg-savoy-blue { //carbaglu
  background-color: #5a5eac !important;
}

.text-savoy-blue {
  color: #5a5eac !important;
}

.bg-plum { //ledaga
  background-color: #954D98 !important;
}

.text-plum {
  color: #954D98 !important;
}


.bg-fire-engine-red { //recordati
  background-color: #D5191D !important;
}

.text-fire-engine-red {
  color: #D5191D !important;
}

.bg-caput-mortuum { //panhematin
  background-color: #542115 !important;
}

.text-caput-mortuum {
  color: #542115 !important;
}

.bg-asparagus { //isturisa
  background-color: #5FA84A !important;
}

.text-asparagus {
  color: #5FA84A !important;
}